<template>
  <div>
    <div v-if="containers">
      <nav
        aria-label="breadcrumb"
        v-if="currentStructure.length >= 1 || editMode"
      >
        <ol class="breadcrumb mb-0">
          <li>
            <NewParent
              name="Create New Root"
              v-show="editMode"
              :toggleEditMode="toggleEditMode"
            />
          </li>
          <li
            class="breadcrumb-item"
            v-for="parent in currentStructure"
            v-bind:key="parent.id + 'parent'"
          >
            <span @click="setActiveParent(parent.id)" class="clickable"
              >{{ parent.name }}
            </span>
          </li>
        </ol>
      </nav>
      <div>
        <v-icon name="mdiBed"></v-icon>
        <ul class="nav nav-tabs mb-4" style="position: relative;">
          <div v-for="nav in navs" v-bind:key="nav.id">
            <Tabs
              :isAdmin="isAdmin || isFulfillerUser"
              :name="nav.name"
              :setActiveParent="setActiveParent"
              :id="nav.id"
              :currentID="currentID"
            />
          </div>
          <NewTab
            :currNavs="navs"
            name="Add New"
            :toggleEditMode="toggleEditMode"
            v-if="currentStructure.length >= 1 && editMode"
          />
          <div v-if="isAdmin || isFulfillerUser" class="tab-nav-edit" @click="toggleEditMode">
            <span style="margin-right: 8px;">
              <Icon icon-name="pencil-alt" size="3.8px" color="grey"
            /></span>
            <span v-if="editMode" @click.stop="toggleEditMode">Cancel</span>
            <span v-else>Edit</span>
          </div>
        </ul>
        <div>
          <b-card-group class="text-center flex-row" deck :if="getContainers">
            <div
              v-for="container in sortedContainers"
              v-bind:key="container.id"
            >
              <div v-if="container.children.length > 0">
                <ContainerCard
                  :container="container"
                  :setActiveParent="setActiveParent"
                />
              </div>
              <div v-if="container.children && container.children.length === 0">
                <ContainerCard :container="container" />
              </div>
            </div>
            <NewCard
              :currNavs="navs"
              :currID="currentID"
              name="Add New"
              :getMyIds="getMyIds"
              :getContainers="getContainers"
              :toggleEditMode="toggleEditMode"
              v-if="navs.length >= 1 && editMode"
            />
          </b-card-group>
        </div>
      </div>
    </div>
    <div v-if="!loading && sortedContainers.length === 0">
      <v-card elevation="2" style="padding: 2% 2% 0.5% 2%; min-height: 300px; ">
        <h4>{{ $t('containers.noContainersMsg') }}</h4>

        <h4>{{ $t('containers.createFirstContainer') }}</h4>
        <br />
        <v-text-field
          label="Name"
          v-model="newContainerData.name"
          class="pa-3"
          style="max-width: 400px; margin: auto;"
        ></v-text-field>
        <br />
        <b-button
          variant="primary"
          style="color: white;"
          @click.stop="addContainer()"
        >
          {{ $t('containers.firstContainerBtn') }}
        </b-button>
      </v-card>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Tabs from '@/components/containers/Tabs'
import { cloneDeep } from 'lodash'
import TreeModel from 'tree-model-improved'
const ContainerCard = () => import('@/components/containers/ContainerCard')
const NewTab = () => import('@/components/containers/NewTab')
const NewCard = () => import('@/components/containers/NewCard')
const Icon = () => import('@/components/elements/Icon')
const NewParent = () => import('@/components/containers/NewParent')
const treeModel = new TreeModel({
  modelComparatorFn: (a, b) => a.name?.toLowerCase() > b.name?.toLowerCase()
})

export default {
  name: 'ContainersOverview',
  components: {
    ContainerCard,
    NewParent,
    NewCard,
    Icon,
    Tabs,
    NewTab
  },
  props: {
    params: {
      type: Object
    }
  },
  data () {
    return {
      currentStructure: [],
      loading: true,
      currentID: '',
      navs: [],
      containers: [],
      editMode: false,
      newContainerData: {
        name: '',
        devices: [],
        children: [],
        metadata: {
          name: '',
          roomCode: 0,
          status: '',
          text: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getContainers: 'containers/getContainers',
      getMyIds: 'login/getMyIds',
      getContainerInfo: 'getContainerInfo',
      isAdmin: 'login/isAdmin',
      isFulfillerUser: 'login/isFulfillerUser',
      getRootContainer: 'containers/getRootContainer'
    }),
    sortedContainers: function () {
      if (this?.containers?.children) {
        const currContainers = this.containers.children
        return currContainers.sort(function (i, j) {
          return j.children.length - i.children.length
        })
      } else if (this.containers) {
        if (this.containers === []) return []
        try {
          const collator = new Intl.Collator(undefined, {
            numeric: true,
            sensitivity: 'base'
          })
          const containers = this.containers
          const containerItem = containers?.[0]
          if (containerItem && containerItem.hasOwnProperty('name')) {
            containers.sort(function (a, b) {
              return collator.compare(a.name, b.name)
            })
            return containers
          }
        } catch (err) {
          console.log('Container sort error: ', err)
        }
        // don't show device containers
        return this.containers?.filter(
          container => !container.renderingMetadata?.deviceId
        )
      }
      return []
    },
    filteredNavs: function () {
      return this.navs.filter(item => item.children.length > 0)
    }
  },
  methods: {
    ...mapActions({
      updateContainer: 'containers/updateContainer',
      postContainer: 'containers/postContainer',
      fetchContainers: 'containers/fetchContainers',
      fetchRegisteredDevices: 'devices/fetchRegisteredDevices'
    }),
    toggleEditMode () {
      this.editMode = !this.editMode
    },
    createNewContainer () {
      let currTree = treeModel.parse(cloneDeep(this.getContainers))
      this.newContainerData.customerId = this.getMyIds?.CUSTOMER?.[0] ?? 'DEFAULT'
      this.newContainerData.fulfillerId = this.getMyIds?.FULFILLER?.[0] ?? 'DEFAULT'

      let currNode = currTree.first(node => {
        return node.model.id === this.currentID // grab sibling level of new Container
      })
      if (currNode) {
        currNode.addChild(treeModel.parse(this.newContainerData))
      } else {
        currTree.addChild(treeModel.parse(this.newContainerData))
      }

      this.updateContainer({
        container: currTree.model,
        msgType: 'container_create',
        updatedObj: this.newContainerData
      })
    },
    addContainer () {
      if (this.navs.length === 0 && this.sortedContainers.length === 0) {
        this.createFirstContainer()
      } else {
        this.createNewContainer()
      }
    },
    createFirstContainer () {
      console.log('createFirstContainer', this.newContainerData)
      this.newContainerData.customerId = this.getMyIds?.CUSTOMER?.[0] ?? 'DEFAULT'
      this.newContainerData.fulfillerId = this.getMyIds?.FULFILLER?.[0] ?? 'DEFAULT'
      this.postContainer(this.newContainerData)
    },
    setActiveParent (id) {
      if (id !== this.currentID) {
        this.$router.push({ name: 'Containers', params: { id: id } })
      }
    },
    initContainer (id) {
      let answer = {}
      if (id) {
        this.loading = true

        function buildStructure (curr, array, activeId, parent) {
          let currArray = cloneDeep(array)

          if (curr.id === activeId) {
            answer = { curr: curr, array: currArray, container: parent }
          } else if (curr.children) {
            currArray.push(curr)
            curr.children.forEach(item => {
              buildStructure(item, currArray, activeId, curr)
            })
          }
        }
        let gContainers = cloneDeep(this.getContainers) // CloneDeep Fixes the Mutation Console Warnings
        buildStructure(gContainers, [], id, gContainers)
        this.navs = answer?.array?.length
          ? answer.container.children
          : [answer.curr]
        this.containers = answer.curr.children
        this.currentStructure = answer.array
        this.loading = false
      }
    },
    async reload () {
      this.loading = true
      try {
        console.log('we are mounting Container Overview')
        await this.fetchContainers()
        this.loading = false
      } catch (err) {
        this.loading = false
        console.log('containers mounted error', err)
      }

      if (this.$route.params.id) {
        this.currentID = this.$route.params.id
        this.setActiveParent(this.$route.params.id)
      }
      if (!this.$route.params.id && this.navs.length) {
        this.currentID = this.navs[0].id
      }
    }
  },
  async mounted () {
    await this.reload()
  },
  watch: {
    '$route.params.id' (to, from) {
      if (to) {
        this.currentID = to
        return
      } else {
        this.currentStructure = []
        this.fetchContainers()
        this.loading = false
      }
      this.setActiveParent(to)
    },
    // not need to reload, when current rootContainer is changed, api getContainers api getContainerAsList and api getRegisteredDevices, will reload.. in action chooseRootContainer
    // async getRootContainer (newVal, oldVal) {
    //   if (newVal !== oldVal && !this.$route.params.id) {
    //     await this.reload()
    //   }
    // },
    getContainers (newVal, oldVal) {
      if (newVal.length === 0) {
        this.currentStructure = []
        this.containers = []
        this.navs = []
        return
      }

      if (newVal?.id) {
        this.initContainer(newVal.id)
      }
    },
    currentID (newVal, oldVal) {
      this.initContainer(newVal)
    }
  }
}
</script>
